import * as gtag from 'vue-gtag';

/**
 * Add Google Tag Manager (gtag) to site using vue-gtag module.
 * @see: https://github.com/nuxt-community/gtm-module/issues/82#issuecomment-762153556
 */
export default defineNuxtPlugin((nuxtApp) => {
  const { gtagId, isVitest } = useRuntimeConfig().public;
  const { query } = useRoute();

  if (isVitest) {
    console.log('Google Tag: Not loaded, in Vitest!');
    return;
  }

  if (!gtagId) {
    console.log('Google Tag: Not loaded, missing gtagId');
    return;
  }

  if (query.in_app_view === 'true') {
    console.log('Google Tag: Not loaded, in app view');
    return;
  }

  const config = {
    id: gtagId,
    params: {
      link_attribution: true,
      custom_map: {
        dimension2: 'status',
      },
    },
  };

  nuxtApp.vueApp.use(gtag.default, { config });

  return {
    provide: {
      gtag,
    },
  };
});
