<template>
  <a
    v-if="href"
    :class="classNames"
    :disabled="disabled"
    :href="href"
    :rel="rel"
    :target="target"
    :title="title"
    @click="handleClick"
    @mousedown="handleMousedown"
  >
    <slot />
  </a>
  <NuxtLink
    v-else-if="to"
    :class="classNames"
    :disabled="disabled"
    :title="title"
    :to="to"
    @click.native="handleClick"
    @mousedown="handleMousedown"
  >
    <slot />
  </NuxtLink>
  <button
    v-else
    :class="classNames"
    :disabled="disabled"
    :title="title"
    @click="handleClick"
    @mousedown="handleMousedown"
  >
    <font-awesome-icon
      v-if="isChip && isActive && canShowChipActiveIcon"
      class="tw-text-white tw-fill-current tw-mr-1 tw-align-middle"
      icon="check-circle"
    />
    <slot />
  </button>
</template>

<script>
/* eslint vue/no-reserved-component-names: off, vue/no-deprecated-v-on-native-modifier: off */

export const ButtonTypes = {
  allAccess: 'allAccess',
  bannerBuyNow: 'bannerBuyNow',
  card: 'card',
  chip: 'chip',
  chipCard: 'chipCard',
  chipCardAllAccess: 'chipCardAllAccess',
  chipSecondary: 'chipSecondary',
  danger: 'danger',
  help: 'help',
  homePageBuyNow: 'homePageBuyNow',
  homePageBuyNowThemeable: 'homePageBuyNowThemeable',
  none: 'none',
  pill: 'pill',
  pillSecondary: 'pillSecondary',
  primary: 'primary',
  secondary: 'secondary',
  secondaryCard: 'secondaryCard',
  upgrade: 'upgrade',
};

/**
 * The <Button> component will render an <a> element when the href prop is specified, or a
 * <NuxtLink> component when the to prop is specified, or else it will render a <button>. In all
 * cases a click event will be emitted to the parent component when the <Button> is clicked.
 */
export default {
  name: 'Button',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    additionalClassNames: {
      type: String,
      default: '',
    },
    canUnderlineOnHover: {
      type: Boolean,
      default: false,
    },
    canShowChipActiveIcon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    displayBlock: {
      type: Boolean,
      default: false,
    },
    extraLarge: {
      type: Boolean,
      default: false,
    },
    isStrongButton: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
    rel: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: ButtonTypes.primary,
      validator(value) {
        return Object.values(ButtonTypes).includes(value);
      },
    },
  },

  emits: ['click', 'mousedown'],

  computed: {
    classNames() {
      let px = 'tw-px-3';
      px = this.isPill ? 'tw-px-4' : px;
      px = this.small ? 'tw-px-2' : px;
      px = this.large ? 'tw-px-4' : px;
      px = this.extraLarge ? 'tw-px-4' : px;

      let py = this.large ? 'tw-py-2' : 'tw-py-1.5';
      py = this.extraLarge ? 'tw-py-3' : py;

      return [
        this.isActive ? this.$style.active : null,
        this.displayBlock ? 'tw-block' : 'tw-inline-block',
        this.isPill ? 'tw-rounded-full' : 'tw-rounded-lg',
        this.isNone ? null : py,
        this.isNone ? null : px,
        'tw-transition-all',
        'tw-no-underline',
        'tw-font-normal',
        'tw-text-center',
        this.isPill || this.small ? 'tw-text-sm' : 'tw-text-base',
        this.isStrongButton ? 'tw-shadow-md' : null,
        this.canUnderlineOnHover ? 'hover:tw-underline' : 'hover:tw-no-underline',
        this.disabled ? 'tw-opacity-50 tw-pointer-events-none' : null,
        this.disableHover ? 'tw-pointer-events-none' : null,
        this.large ? 'tw-text-xl' : null,
        this.useWhiteText ? 'tw-text-white' : null,
        this.$style[this.type],
        this.isStrongButton ? this.$style.strongButton : null,
        this.additionalClassNames,
      ].filter((name) => name !== null);
    },

    isActive() {
      return this.active && (this.isPill || this.isChip);
    },

    isChip() {
      return [
        ButtonTypes.chip,
        ButtonTypes.chipCard,
        ButtonTypes.chipCardAllAccess,
        ButtonTypes.chipSecondary,
      ].includes(this.type);
    },

    isNone() {
      return this.type === ButtonTypes.none;
    },

    isPill() {
      return [ButtonTypes.pill, ButtonTypes.pillSecondary].includes(this.type);
    },

    useWhiteText() {
      const buttonTypes = [ButtonTypes.bannerBuyNow, ButtonTypes.help, ButtonTypes.none];

      buttonTypes.push(
        ButtonTypes.chipSecondary,
        ButtonTypes.homePageBuyNowThemeable,
        ButtonTypes.secondary,
        ButtonTypes.secondaryCard,
        ButtonTypes.card,
        ButtonTypes.pill,
        ButtonTypes.pillSecondary,
      );

      if (this.type === ButtonTypes.chip && !this.active) {
        buttonTypes.push(ButtonTypes.chip);
      }

      if (this.type === ButtonTypes.chipCard && !this.active) {
        buttonTypes.push(ButtonTypes.chipCard);
      }

      if (this.type === ButtonTypes.chipCardAllAccess && !this.active) {
        buttonTypes.push(ButtonTypes.chipCardAllAccess);
      }

      return buttonTypes.includes(this.type) === false;
    },
  },

  methods: {
    handleClick(e) {
      if (this.disabled) {
        e.preventDefault();
        e.stopPropagation();
      }
      else {
        this.$emit('click', { originalEvent: e });
      }

      if (e?.target) {
        e.target.blur();
      }
    },

    handleMousedown(e) {
      if (this.disabled) {
        e.preventDefault();
        e.stopPropagation();
      }
      else {
        this.$emit('mousedown', { originalEvent: e });
      }
    },
  },
};
</script>

<style module>
.allAccess {
  background-color: var(--saturated-teal);
}

@media (hover: hover) and (pointer: fine) {
  .allAccess:hover {
    background-color: var(--dark-teal);
  }
}

.allAccess:focus {
  background-color: var(--dark-teal);
}

.bannerBuyNow {
  background-color: white;
  color: var(--very-dark-grayish-blue);
}

@media (hover: hover) and (pointer: fine) {
  .bannerBuyNow:hover {
    background-color: var(--lighter-gray);
  }
}

.card {
  background-color: var(--card-background);
  border: 1px solid var(--card-border);
  color: var(--light-blue);
}

@media (hover: hover) and (pointer: fine) {
  .card:hover {
    color: var(--saturated-blue);
  }
}

.danger {
  background-color: var(--saturated-red);
}

@media (hover: hover) and (pointer: fine) {
  .danger:hover {
    background-color: var(--saturated-red-dark-20);
  }
}

.danger:focus {
  background-color: var(--saturated-red-dark-20);
}

.help {
  background-color: rgb(var(--button-help-background-rgb));
  color: var(--button-help-color);
}

@media (hover: hover) and (pointer: fine) {
  .help:hover {
    background-color: var(--button-help-background-hover);
    color: var(--button-help-color-hover);
  }
}

.help:focus {
  background-color: var(--button-help-background-hover);
  box-shadow: 0 0 0 0.2rem var(--button-help-focus-box-shadow-color);
}

.homePageBuyNow {
  background-color: rgb(0 0 0 / 10%);
  border: calc(0.125rem) solid white;
  color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .homePageBuyNow:hover {
    background-color: rgb(0 0 0 / 30%);
  }
}

.homePageBuyNowThemeable {
  background-color: var(--text-lightest);
  border: calc(0.125rem) solid var(--text-darkest);
  color: var(--text-darkest);
}

@media (hover: hover) and (pointer: fine) {
  .homePageBuyNowThemeable:hover {
    background-color: rgb(var(--lighter-gray-rgb) / 30%);
  }
}

.pill {
  background-color: var(--card-background);
  border: 1px solid var(--card-border);
  color: var(--text-dark);
}

.pill.active {
  background-color: var(--light-blue);
  color: white;
}

.pill.strongButton {
  border-color: rgb(var(--card-border-rgb) / 35%);
}

@media (hover: hover) and (pointer: fine) {
  .pill:hover {
    background-color: var(--light-blue);
    color: white;
  }
}

.chipSecondary,
.pillSecondary {
  background-color: transparent;
  color: var(--text-dark);
}

.chipSecondary.active,
.pillSecondary.active {
  background-color: var(--lighter-gray);
}

@media (hover: hover) and (pointer: fine) {
  .chipSecondary:hover,
  .pillSecondary:hover {
    background-color: var(--lighter-gray);
  }
}

:global([data-theme="dark"]) .chipSecondary.active,
:global([data-color-theme="dark"]) .chipSecondary.active,
:global([data-theme="dark"]) .pillSecondary.active,
:global([data-color-theme="dark"]) .pillSecondary.active {
  color: var(--card-background);
}

@media (hover: hover) and (pointer: fine) {
  :global([data-theme="dark"]) .chipSecondary:hover,
  :global([data-color-theme="dark"]) .chipSecondary:hover,
  :global([data-theme="dark"]) .pillSecondary:hover,
  :global([data-color-theme="dark"]) .pillSecondary:hover {
    color: var(--card-background);
  }
}

.chip.active,
.chipCard.active,
.primary {
  background-color: rgb(var(--button-primary-background-rgb));
}

.chipCardAllAccess.active {
  background-color: var(--saturated-teal);
}

@media (hover: hover) and (pointer: fine) {
  .chip.active:hover,
  .chipCard.active:hover,
  .primary:hover {
    background-color: var(--button-primary-background-hover);
  }

  .chipCardAllAccess.active:hover {
    background-color: var(--dark-teal);
  }
}

.chip.active:focus,
.chipCard.active:focus,
.primary:focus {
  background-color: var(--button-primary-background-hover);
  box-shadow: 0 0 0 0.2rem rgb(var(--button-primary-background-rgb) / 25%);
}

.chip,
.secondary {
  background-color: rgb(var(--button-secondary-background-rgb));
  border: var(--button-secondary-border);
  color: var(--text-darkest);
}

.chipCard,
.chipCardAllAccess {
  background-color: var(--card-background);
  border: var(--button-secondary-border);
  color: var(--text-darkest);
}

@media (hover: hover) and (pointer: fine) {
  .chip:hover,
  .chipCard:hover,
  .chipCardAllAccess:hover,
  .secondary:hover {
    background-color: var(--button-secondary-background-hover);
  }
}

.chip:focus,
.chipCard:focus,
.chipCardAllAccess:focus,
.secondary:focus {
  background-color: var(--button-secondary-background-hover);
  box-shadow: 0 0 0 0.2rem rgb(var(--button-secondary-background-rgb) / 25%);
}

.secondaryCard {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  color: var(--text-darkest);
}

@media (hover: hover) and (pointer: fine) {
  .secondaryCard:hover {
    background-color: var(--button-secondary-background-hover);
  }
}

.secondaryCard:focus {
  background-color: var(--button-secondary-background-hover);
  box-shadow: 0 0 0 0.2rem rgb(var(--button-secondary-background-rgb) / 25%);
}

.upgrade {
  background-color: var(--saturated-teal-dark-05);
  border: solid 0.125rem var(--saturated-teal-dark-10);
}

@media (hover: hover) and (pointer: fine) {
  .upgrade:hover {
    background-color: var(--saturated-teal-dark-08);
  }
}

.upgrade:focus {
  background-color: var(--saturated-teal-dark-08);
}
</style>
