/**
 * The check user middleware will attempt to fetch the user so that SSR pages can be rendered
 * correctly for authenticated users. Fetching the user also results in canShowAuthContent being
 * set to true which may be required by some pages in order to render! If the page uses the
 * authorized template and the user is a guest then the user will be redirected to the /user/login
 * or /user/register pages.
 * @see https://stackoverflow.com/questions/73293352/nuxt-3-how-to-use-route-middleware-in-a-layout-can-i
 */
import { bannerTypes } from '@@/components/Common/Banner.vue';
import { getPromoCodeBannerType } from '@@/utils/LoginUtils';
import { useMetaStore } from '@@/stores/Meta';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import { useUserStore } from '@@/stores/User';

export default defineNuxtRouteMiddleware(async (to) => {
  const event = useRequestEvent();
  const metaStore = useMetaStore();
  const userFavoritesStore = useUserFavoritesStore();
  const userStore = useUserStore();

  // Fetch the user and seed/pricing data in parallel,
  // Only fetch the user if it hasn't been fetched yet in this app lifecycle.
  // Anytime any response from the API returns a user object with a new cache_key,
  // any dependent data like user favorite ids will be re-fetched (see plugins/api.js)
  await Promise.all([
    (!userStore.user)
      ? userStore.fetchUser(event)
      : true,
    to.meta.layout === 'buy'
      ? metaStore.fetchPricing()
      : metaStore.fetchSeed(),
  ]);

  // DEBUG: These are helpful for debugging so they are left as reminders.
  // console.log(`checkUser(): to.meta.layout = ${to.meta.layout}, userStore.isGuest = ${userStore.isGuest}`);
  // console.log('checkUser(): to = ', to);

  const { query } = to;

  if (to.meta.layout === 'authorized' && userStore.isGuest) {
    const return_to = to.fullPath;
    const path = return_to.includes('user/') ? '/user/login' : '/user/register';

    return navigateTo({
      path,
      query: { ...query, return_to },
    });
  }

  if (to.meta.layout === 'guest' && !userStore.isGuest) {
    return navigateTo({
      path: '/user/favorites/locations',
      query,
    });
  }

  if (to.meta.layout === 'guest-or-apply-promo-code' && !userStore.isGuest) {
    if (to.query.promo_code) {
      const banner = { message: '', type: null };

      try {
        const { messages, promo_code } = await userStore.applyPromoCode(to.query.promo_code);
        banner.message = messages.join(' ');
        banner.type = getPromoCodeBannerType(promo_code);
      }
      catch (e) {
        banner.message = e?.messages
          ? e.messages.join(' ')
          : `An error occurred applying the promo code ${to.query.promo_code}.`;
        banner.type = bannerTypes.error;
      }

      // Save banner to a short lived cookie since state cannot be persisted to Pinia from SSR
      // middleware nor can state be persisted to local storage when this middleware runs on the
      // server.
      // SEE: https://stackoverflow.com/questions/69340160/synchronize-vuex-store-with-server-side-in-nuxt-js
      // side note, will this middleware ever run on the server if we've configured all /user routes to be client rendered only?
      userStore.setBanner({ banner, saveToCookie: true });
    }

    return navigateTo({
      path: '/user/favorites/locations',
      query,
    });
  }

  if (!userStore.isGuest) {
    const { user } = userStore;
    await Promise.all([
      userFavoritesStore.fetchActiveList({ user }),
      userFavoritesStore.fetchUserFavoriteIds(),
    ]);
  }
});
