<template>
  <div
    v-click-away="handleClickAway"
    :class="[
      $style.container,
      'tw-min-w-full',
      'tw-relative',
    ]"
  >
    <div :class="inputContainerClass">
      <font-awesome-icon
        class="tw-w-4 tw-h-8 tw-mr-2"
        icon="search"
      />
      <input
        v-model="query"
        autocomplete="new-search-query"
        :class="inputClass"
        data-lpignore="true"
        placeholder="Search for ski resorts, cities & more..."
        type="search"
        @input="getSearchResults"
      >
    </div>
    <DropdownMenu
      :card-props="cardProps"
      class="tw-w-full"
      :close-on-click-away="false"
      :is-menu-full-width="true"
      :show-menu="showMenu"
      @close="handleDropdownClose"
    >
      <template #menu>
        <p
          v-if="noResults"
          class="tw-py-5 tw-px-2.5 tw-text-black"
        >
          No results for "{{ query }}".
        </p>
        <ul v-else>
          <li
            v-for="result in results"
            :key="getListItemKey(result)"
            :class="listItemClass"
            @click="() => selectResult(result)"
          >
            <div :class="locationTypeIconClass">
              <div
                class="tw-h-full tw-w-full"
                :style="getLocationTypeIconStyle(result.icon_url)"
              />
            </div>
            <div>
              <strong class="tw-block tw-text-base text-darkest-color">
                {{ result.label }}
              </strong>
              <span class="tw-block tw-text-xs text-regular-color">
                {{ result.detail }}
              </span>
            </div>
          </li>
        </ul>
      </template>
    </DropdownMenu>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { mixin as clickaway } from 'vue3-click-away';
import debounce from 'lodash.debounce';
import {
  addViewToComparePath,
  addViewToLocationPath,
} from '@@/components/Compare/Common/CompareUtils';
import { getMaskImageStyle } from '@@/utils/CommonUtils';
import { useMapStore } from '@@/stores/Map';
import { useSearchStore } from '@@/stores/Search';
import { useUiStore } from '@@/stores/Ui';

/**
 * The <SiteHeaderSearch> performs a look ahead search for all search types: Locations, States,
 * Regions, Countries, Passes, and Daily Snows. If the user is on the map page, and a location is
 * selected, then the selected location will be displayed on the map. And when the user is not on
 * the map page, then the user will navigate to the appropriate page when a result is selected
 * based on its type (e.g. /daily-snows, /explore/regions, /location, etc).
 */
export default {
  name: 'SiteHeaderSearch',

  mixins: [clickaway],

  data() {
    return {
      noResults: false,
      query: '',
      results: [],
      showMenu: false,
      timeout: null,
    };
  },

  computed: {
    cardProps() {
      return {
        cardClassNames: `tw-w-full tw-overflow-auto ${this.$style.resultsContainer}`,
        hasBodyPadding: false,
        hasBodyShadow: true,
      };
    },

    inputClass() {
      return [
        'tw-inline-block tw-h-10 tw-py-1.5 lg:tw-py-2 tw-border-none',
        'focus:tw-outline-none focus:tw-shadow-none',
        this.$style.input,
      ];
    },

    inputContainerClass() {
      return [
        'tw-flex tw-items-center tw-w-full tw-px-4',
        'tw-text-white tw-text-base lg:tw-text-sm',
        this.$style.inputContainer,
      ];
    },

    listItemClass() {
      return [
        'tw-flex tw-items-start lg:tw-items-center',
        'tw-py-2 tw-px-3',
        'tw-border-b last:tw-border-0 border-color',
        'tw-cursor-pointer',
        this.$style.listItem,
      ];
    },

    locationTypeIconClass() {
      return [
        'tw-block tw-w-8 tw-h-8 tw-p-1 tw-rounded-sm tw-mr-2.5',
        'tw-flex-grow-0 tw-flex-shrink-0',
        this.$style.locationTypeIcon,
      ];
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapLocation']),
    ...mapActions(useSearchStore, ['searchAll']),
    ...mapActions(useUiStore, ['setShouldCollapseNav']),

    getListItemKey(result) {
      return `${result.slug}-${result.type}`;
    },

    getLocationTypeIconStyle(iconUrl) {
      if (String(iconUrl).match(/\.png$/)) {
        return {
          backgroundImage: `url(${iconUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        };
      }

      return {
        backgroundColor: 'var(--text-darkest)',
        ...getMaskImageStyle(iconUrl),
      };
    },

    getSearchResults: debounce(async function getSearchResults() {
      try {
        const q = String(this.query).trim();

        if (!q) {
          this.reset();
          return;
        }

        // The API returns a 400 error if the query is less than 3 characters
        if (q.length < 3) {
          return;
        }

        this.results = await this.searchAll({ q });
        this.noResults = !this.results.length;
        this.showMenu = true;
      }
      catch (e) {
        // Ignore API errors and let the user try again
      }
    }, 500),

    handleClickAway() {
      this.reset();
    },

    handleDropdownClose() {
      this.reset();
    },

    reset() {
      window.clearTimeout(this.timeout);
      this.showMenu = false;

      // Clear the query and results after the drop down closes so that there is content to hide.
      // If these were reset immediately then the dropdown content would disappear before the close
      // animation and the transition to close the dropdown would look awkward.

      this.timeout = window.setTimeout(() => {
        this.noResults = false;
        this.query = '';
        this.results = [];
        this.timeout = null;
      }, 250);
    },

    async selectResult(result) {
      const currentPath = this.$route.fullPath;
      let path = '';

      this.reset();
      await this.$nextTick();

      const onMapPage = this.$route.path.match(/^\/map/);

      if (onMapPage && result.type === 'location') {
        const [lng, lat] = result.coordinates.point;
        const shortname = result.slug;
        this.setMapLocation({ lat, lng, shortname });
      }
      else {
        const { slug, type } = result;

        switch (type) {
          case 'avalanche-region':
            path = `/avalanche/${slug}`;
            break;

          case 'country':
            path = addViewToComparePath(`/explore/countries/${slug}`, { currentPath });
            break;

          case 'daily-read':
            path = `/dailysnow/${slug}`;
            break;

          case 'region':
            path = addViewToComparePath(`/explore/regions/${slug}`, { currentPath });
            break;

          case 'season-pass':
            path = addViewToComparePath(`/explore/season-passes/${slug}`, { currentPath });
            break;

          case 'state':
            path = addViewToComparePath(`/explore/states/${slug}`, { currentPath });
            break;

          default:
            path = addViewToLocationPath(`/location/${slug}`, { currentPath });

            // If the view for the current path wasn't added to the location path then use the
            // URL returned from the server which will navigate to the snow-summary page for
            // skiable locations, and the weather page for all other locations.

            if (path === `/location/${slug}`) {
              const url = new URL(result.url);
              path = url.pathname;
            }

            break;
        }
      }

      this.setShouldCollapseNav(true);

      const router = useRouter();
      router.push(path);
    },
  },
};
</script>

<style module>
.inputContainer {
  background-color: var(--header-search-background-color);
  border-radius: 0.625rem;
}

.input {
  background-color: inherit;
  border-radius: 0 0.625rem 0.625rem 0;
  width: calc(100% - 2rem);
}

.input::placeholder {
  color: white;
}

.resultsContainer {
  max-height: 18rem;
}

@media (width >= 992px) {
  .resultsContainer {
    max-height: calc(90vh - 64px); /* Minus 64px for the site header */
  }
}

.listItem:hover {
  background-color: var(--header-search-result-hover-background-color);
}

.locationTypeIcon {
  background-color: var(--card-border);
}
</style>
