// if in local environment, nginx rewrites any /weblink/something* paths to /something*, and the server never sees the /weblink/ part in the request
// if in dev or higher environment, cloudflare handles that rewrite with the same behavior.
// -- nb: we could probably remove the cloudflare rewrite and just let the nginx rewrite handle it...
// the REWRITE vs REDIRECT piece is important, as iOS will pick up on redirects and try to launch the app if it's a url that the app can handle,
// but the whole point of /weblink/ is preventing the app from taking over in situations where we don't want it to

// _however_, for pages that are client side-rendered only, NOT SSR rendered, the nuxt app can't figure out how to route
// those pages because the browser address bar still contains the /weblink/ part
// so, in those cases, this middleware kicks in.
export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client && to.fullPath.startsWith('/weblink/')) {
    return navigateTo(to.fullPath.replace('/weblink', ''));
  }
});
