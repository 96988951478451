import validate from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45weblink_45redirect_45global from "/buddy/pipeline-fs/middleware/01.weblink-redirect.global.js";
import _02_45check_45user_45global from "/buddy/pipeline-fs/middleware/02.check-user.global.js";
import manifest_45route_45rule from "/buddy/pipeline-fs/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45weblink_45redirect_45global,
  _02_45check_45user_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}