<template>
  <nav :class="navClass">
    <div class="tw-h-11 tw-py-1.5 lg:tw-mr-4">
      <slot name="brand">
        <h1>OpenSnow</h1>
      </slot>
    </div>
    <button
      class="lg:tw-hidden tw-h-11 tw-text-xl"
      @click="handleToggleButtonClick"
    >
      <font-awesome-icon
        :icon="['far', 'bars']"
      />
    </button>
    <div :class="menuClass">
      <slot name="menu" />
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useUiStore } from '@@/stores/Ui';

export default {
  name: 'SiteNavBar',

  data() {
    return {
      isMenuCollapsed: true,
    };
  },

  computed: {
    ...mapState(useUiStore, ['shouldCollapseNav']),

    menuClass() {
      return [
        'lg:tw-flex lg:tw-justify-end lg:tw-items-center',
        'tw-flex-grow',
        this.$style.menu,
        this.isMenuCollapsed ? this.$style.menuCollapsed : this.$style.menuExpanded,
      ];
    },

    navClass() {
      return [
        'tw-flex tw-flex-wrap tw-items-center tw-justify-between lg:tw-justify-start',
        'tw-w-full tw-px-4 tw-py-2 lg:tw-py-1.5',
        'brand-color',
      ];
    },
  },

  watch: {
    shouldCollapseNav(newValue) {
      if (newValue) {
        this.handleCollapse();
        this.setShouldCollapseNav(false);
      }
    },
  },

  methods: {
    ...mapActions(useUiStore, ['setShouldCollapseNav']),

    handleCollapse() {
      this.isMenuCollapsed = true;
    },

    handleToggleButtonClick() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
  },
};
</script>

<style module>
.menu {
  flex-basis: 100%;
  transition: max-height 0.35s ease;
}

.menuCollapsed {
  max-height: 0;
  overflow: hidden;
}

.menuExpanded {
  max-height: 25rem;
  overflow: hidden;
}

@media (width >= 992px) {
  .menu {
    flex-basis: auto;
  }

  .menuCollapsed,
  .menuExpanded {
    max-height: unset;
    overflow: visible;
  }
}
</style>
