/* eslint camelcase: off */
import { defineStore } from 'pinia';
import { localStorageService, sessionStorageService } from '@@/utils/StorageService';

export const paymentTypes = {
  stripe: 's',
  apple: 'i',
  google: 'a',
};

/**
 * @todo Move alert area notifications to the UserNotifications Vuex module so that all
 * notifications are managed in a single Vuex module.
 */
export const useUserStore = defineStore('user', {
  state: () => ({
    canShowAuthContent: false,
    banner: null,
    location: null,
    notifications: null,
    preferences: {
      theme: 'light',
      units: 'imperial',
    },
    user: null,
  }),

  actions: {
    async fetchNotificationAlertAreas() {
      if (this.isGuest) {
        return;
      }

      const { $api } = useNuxtApp();
      const { cache_key } = this.user;

      const response = await $api.get('/user/notifications/alert-areas', { cache_key });
      this.setNotifications(response);
    },

    setNotifications({ alert_areas, locations }) {
      if (!this.notifications) {
        this.notifications = {};
      }

      this.notifications.alert_areas = alert_areas;
      this.notifications.locations = locations;
    },

    async fetchUser(event) {
      try {
        const { $api } = useNuxtApp();
        let user;

        if (import.meta.server) {
          ({ user } = await $api.getWithCookie(event, '/user'));
        }
        else {
          ({ user } = await $api.get('/user'));
        }

        this.saveUser(user);

        const { units } = user;
        this.savePreference({ key: 'units', value: units });
      }
      catch (e) {
        // Remove the user record since it couldn't be fetched!
        this.saveUser(null);
      }

      this.setCanShowAuthContent(true);
    },

    /**
     * Fetch the banner to display on the page on the client side.
     *
     * 1. Always use the banner message from session storage or cookie if present. This ensures that
     *    any messages set during registration are always displayed.
     * 2. If the parent page can get the user banner then fetch the user from OpenMountain-API, if
     *    necessary, and use the banner from the user object.
     *
     * In both cases ignore any errors parsing or fetching the banner.
     */
    async getBanner({ canGetUserBanner = false } = {}) {
      // if (process.server) {
      //   return;
      // }

      const bannerMessageItem = sessionStorageService.getItem('bannerMessage');
      const bannerMessageCookie = useCookie('bannerMessage');

      if (bannerMessageItem) {
        try {
          const banner = JSON.parse(bannerMessageItem);
          this.banner = banner;
        }
        catch (e) {
          // Do nothing, ignore invalid value in session storeage.
        }

        sessionStorageService.removeItem('bannerMessage');
      }
      else if (bannerMessageCookie.value) {
        this.banner = bannerMessageCookie.value;
        bannerMessageCookie.value = null;
      }
      else if (canGetUserBanner) {
        try {
          let banner = null;

          if (this.user) {
            ({ banner } = this.user);
          }
          else {
            const { $api } = useNuxtApp();
            const { user } = await $api.get('/user');
            ({ banner } = user);
          }

          this.banner = banner;
        }
        catch (e) {
          // Do nothing, if unable to fetch the banner just don't show it.
        }
      }
    },

    setBanner(payload = {}) {
      const {
        banner,
        saveToCookie = false,
        saveToSessionStorage = false,
      } = payload;

      if (saveToCookie || saveToSessionStorage) {
        const bannerMessage = JSON.stringify(banner);

        if (saveToCookie) {
          const cookie = useCookie('bannerMessage', { maxAge: 10, path: '/' });
          cookie.value = bannerMessage;
        }
        else {
          sessionStorageService.setItem('bannerMessage', bannerMessage);
        }
      }
      else {
        this.banner = banner;
      }
    },

    /**
     * Remove the banner where ever it might be!
     */
    removeBanner() {
      sessionStorageService.removeItem('bannerMessage');
      this.banner = null;
    },

    async setCacheKey(cache_key) {
      if (this.user?.cache_key) {
        this.user.cache_key = cache_key;
      }
    },

    /**
     * Hide content that changes based on whether the user is a guest or authorized to avoid a
     * jarring change in server side rendered content. Components can hide content and gracefully
     * transition it into view once this is set to true. This action will be dispatched after
     * fetching the user info at which point we'll either have a an authorized user or a guest if the
     * GET request fails.
     */
    setCanShowAuthContent(canShowAuthContent) {
      this.canShowAuthContent = canShowAuthContent;
    },

    setLocation(location) {
      // Location is valid for 20 minutes
      const validUntil = Date.now() + (20 * 60 * 1000);
      this.location = { location, validUntil };
    },

    /**
     * Save the user when a new user record is returned from various OpenMountain-API endpoints.
     */
    saveUser(user) {
      this.user = user;
      return true;
    },

    /**
     * This action must be called when running in the browser because the window object is not
     * available when rendered on the server side.
     */
    getTheme() {
      let theme;

      // if (process.server) {
      //   return;
      // }

      const url = new URL(window.location.href);
      const inAppView = url.searchParams.get('in_app_view');

      if (inAppView === 'true') {
        theme = url.searchParams.get('mode') || 'light';
        this.setPreference({ theme });
        return;
      }

      try {
        theme = localStorageService.getItem('theme');
      }
      catch (e) {
        // Do nothing if unable to read from local storage
      }

      if (!theme) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          theme = 'dark';
        }
      }

      if (theme) {
        this.setPreference({ theme });
      }
    },

    /**
     * Guest users will have their units retrieved from the units cookie. All other users
     * will have their units saved in the user object in the database.
     */
    async getUnits() {
      if (!this.isGuest) {
        return undefined;
      }

      let units = useCookie('units');

      if (units.value) {
        if (units.value !== 'metric' && units.value !== 'imperial') {
          units.value = 'imperial';
        }

        this.setPreference({ units: units.value });
      }

      return units.value;
    },

    async applyPromoCode(promo_code) {
      const { $api } = useNuxtApp();
      const body = { promo_code };

      const response = await $api.post('/user/settings/promo-code', undefined, body);
      return response;
    },

    async makeUserSettingsPasswordRequest(password) {
      const { $api } = useNuxtApp();
      const body = { password };

      const response = await $api.post('/user/settings/password', undefined, body);
      return response.password_changed;
    },

    savePreference({ key, value }) {
      this.preferences[key] = value;
    },

    async setPreference(preference) {
      const [[key, value]] = Object.entries(preference);

      this.savePreference({ key, value });

      if (key === 'theme') {
        localStorageService.setItem(key, value);
      }

      if (key === 'units') {
        if (this.isGuest) {
          const unitsCookie = useCookie('units', {
            maxAge: 86400 * 1000,
            path: '/',
          });
          unitsCookie.value = value;
        }
        else {
          const { $api } = useNuxtApp();
          await $api.post('/user/settings/units', undefined, { [key]: value });
        }
      }
    },

    async getGroupMembers() {
      const { $api } = useNuxtApp();
      const response = await $api.get('/user/group');
      return response.members;
    },

    async addGroupMember({ email }) {
      const { $api } = useNuxtApp();
      const response = await $api.post('/user/group', undefined, { email });
      return response.members;
    },

    async removeGroupMember({ id, type }) {
      const { $api } = useNuxtApp();
      const response = await $api.delete(`/user/group/${id}`, { user_type: type });
      return response.members;
    },

    async leaveGroup() {
      const { $api } = useNuxtApp();
      const response = await $api.post('/user/group/leave', undefined, {});
      this.saveUser(response.user);
      return response.user;
    },

    async initStripePortalSession() {
      const { $api } = useNuxtApp();
      const response = await $api.post('/subscription/stripe/portal-session', undefined, {
        return_url: window.location.href,
      });
      return response.url;
    },
  },

  getters: {
    isAllAccess(myState) {
      return myState?.user?.membership?.is_all_access === true;
    },

    isFree(myState) {
      return !myState.isPayingCustomer;
    },

    isChild(myState) {
      return myState?.user?.membership?.is_group_child === true;
    },

    isGuest(myState) {
      return myState.user === null;
    },

    isParent(myState) {
      return myState?.user?.membership?.is_group_owner === true;
    },

    isTrialing(myState) {
      return myState?.user?.membership?.is_trialing === true;
    },

    isPayingCustomer(myState) {
      return myState.isAllAccess && !myState.isTrialing && !myState.isChild;
    },

    isStripeCustomer(myState) {
      return this.isPayingCustomer && myState?.user?.membership?.payment_type === paymentTypes.stripe;
    },

    isAppleCustomer(myState) {
      return this.isPayingCustomer && myState?.user?.membership?.payment_type === paymentTypes.apple;
    },

    isGoogleCustomer(myState) {
      return this.isPayingCustomer && myState?.user?.membership?.payment_type === paymentTypes.google;
    },

    getAlertArea: (myState) => (locationId) => {
      const { alert_areas } = myState?.notifications || {};

      if (alert_areas) {
        return alert_areas.find((alertArea) => alertArea.location_id === locationId);
      }

      return null;
    },

    /**
     * Return null if the cached location is more than 20 minutes old.
     */
    getLocation: (myState) => () => {
      const now = Date.now();
      const { location, validUntil } = myState.location || {};
      return now < validUntil ? location : null;
    },

    hasNotification: (myState) => (locationId) => {
      const { alert_areas } = myState?.notifications || {};

      if (alert_areas) {
        return !!alert_areas.find((alertArea) => alertArea.location_id === locationId);
      }

      return false;
    },
  },
});
