import { default as aboutxIRS5Bg1mXMeta } from "/buddy/pipeline-fs/pages/about.vue?macro=true";
import { default as allaccessu8zGuJI9EKMeta } from "/buddy/pipeline-fs/pages/allaccess.vue?macro=true";
import { default as apioKfDWekqFhMeta } from "/buddy/pipeline-fs/pages/api.vue?macro=true";
import { default as appLZpOciYXZuMeta } from "/buddy/pipeline-fs/pages/app.vue?macro=true";
import { default as indexKWHGcg7dipMeta } from "/buddy/pipeline-fs/pages/avalanche/[slug]/index.vue?macro=true";
import { default as buy3tdfXU0RcTMeta } from "/buddy/pipeline-fs/pages/buy.vue?macro=true";
import { default as careersJAezynu515Meta } from "/buddy/pipeline-fs/pages/careers.vue?macro=true";
import { default as contactZucHUnHjzOMeta } from "/buddy/pipeline-fs/pages/contact.vue?macro=true";
import { default as indexseUQXZf7IPMeta } from "/buddy/pipeline-fs/pages/dailysnow/index.vue?macro=true";
import { default as _91postId_93b9G5rg74e5Meta } from "/buddy/pipeline-fs/pages/dailysnow/post/[postId].vue?macro=true";
import { default as _10_45day_45forecast9Y3XdUwRjRMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/10-day-forecast.vue?macro=true";
import { default as _10_45day_45historyQMfq6ngzDRMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/10-day-history.vue?macro=true";
import { default as all_45camsvlbjNnPcOsMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/all-cams.vue?macro=true";
import { default as indexJZdDAhVyxNMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/index.vue?macro=true";
import { default as mapC3VW67ZcRgMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/map.vue?macro=true";
import { default as snow_45reportsuP4lBFczZMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-report.vue?macro=true";
import { default as summaryXs17DIxnkXMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/summary.vue?macro=true";
import { default as temperatureFWeW1BM9SKMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/temperature.vue?macro=true";
import { default as weather5fTevN7JtsMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/weather.vue?macro=true";
import { default as indexerosbRPyKLMeta } from "/buddy/pipeline-fs/pages/explore/powder/index.vue?macro=true";
import { default as mapJFVZ3ISrMtMeta } from "/buddy/pipeline-fs/pages/explore/powder/map.vue?macro=true";
import { default as indexVEr9aZIHdyMeta } from "/buddy/pipeline-fs/pages/index.vue?macro=true";
import { default as avalanche_45forecastkHVzjPrzaUMeta } from "/buddy/pipeline-fs/pages/location/[slug]/avalanche-forecast.vue?macro=true";
import { default as camsXP95R97yEmMeta } from "/buddy/pipeline-fs/pages/location/[slug]/cams.vue?macro=true";
import { default as daily_45snows3370yRhUrOMeta } from "/buddy/pipeline-fs/pages/location/[slug]/daily-snows.vue?macro=true";
import { default as indexnGdQMrFjWlMeta } from "/buddy/pipeline-fs/pages/location/[slug]/index.vue?macro=true";
import { default as infoAMKSMr0SLyMeta } from "/buddy/pipeline-fs/pages/location/[slug]/info.vue?macro=true";
import { default as snow_45reportcMWhhXFQWOMeta } from "/buddy/pipeline-fs/pages/location/[slug]/snow-report.vue?macro=true";
import { default as snow_45summarylqdGwSl5D2Meta } from "/buddy/pipeline-fs/pages/location/[slug]/snow-summary.vue?macro=true";
import { default as trail_45mapsdfzKFNnwB5Meta } from "/buddy/pipeline-fs/pages/location/[slug]/trail-maps.vue?macro=true";
import { default as weather_45stationsA6OKAlRupsMeta } from "/buddy/pipeline-fs/pages/location/[slug]/weather-stations.vue?macro=true";
import { default as weatheraMWwzrQQI7Meta } from "/buddy/pipeline-fs/pages/location/[slug]/weather.vue?macro=true";
import { default as map18GLMDN5IqMeta } from "/buddy/pipeline-fs/pages/map.vue?macro=true";
import { default as _91category_93axYO0EQ3G4Meta } from "/buddy/pipeline-fs/pages/news/[category].vue?macro=true";
import { default as indexLWWf7Z2GiZMeta } from "/buddy/pipeline-fs/pages/news/index.vue?macro=true";
import { default as _91idOrSlug_934hS0WQx41bMeta } from "/buddy/pipeline-fs/pages/news/post/[idOrSlug].vue?macro=true";
import { default as partnershipsy54PdASnfQMeta } from "/buddy/pipeline-fs/pages/partnerships.vue?macro=true";
import { default as privacyLKEtLAwjr8Meta } from "/buddy/pipeline-fs/pages/privacy.vue?macro=true";
import { default as team1kuMI2e3GhMeta } from "/buddy/pipeline-fs/pages/team.vue?macro=true";
import { default as termsIoFGXRJgfAMeta } from "/buddy/pipeline-fs/pages/terms.vue?macro=true";
import { default as cams9SRLfmRFbZMeta } from "/buddy/pipeline-fs/pages/user/favorites/cams.vue?macro=true";
import { default as daily_45snows5jycoSXQhXMeta } from "/buddy/pipeline-fs/pages/user/favorites/daily-snows.vue?macro=true";
import { default as _10_45day_45forecastgGqhEFofNvMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/10-day-forecast.vue?macro=true";
import { default as _10_45day_45historyEXDiTrD86sMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/10-day-history.vue?macro=true";
import { default as all_45camszEYDYwpLZkMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/all-cams.vue?macro=true";
import { default as indexLeke6xxGj1Meta } from "/buddy/pipeline-fs/pages/user/favorites/locations/index.vue?macro=true";
import { default as snow_45reportxGS3M75tVBMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/snow-report.vue?macro=true";
import { default as summaryv60TAeJycDMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/summary.vue?macro=true";
import { default as temperatureLPDX8kqHzYMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/temperature.vue?macro=true";
import { default as weathern6CQ1MlAZ0Meta } from "/buddy/pipeline-fs/pages/user/favorites/locations/weather.vue?macro=true";
import { default as mapmRW9zHPcjfMeta } from "/buddy/pipeline-fs/pages/user/favorites/map.vue?macro=true";
import { default as season_45passesNa12kHQqrsMeta } from "/buddy/pipeline-fs/pages/user/favorites/season-passes.vue?macro=true";
import { default as trail_45mapsOj6i7Sbj6EMeta } from "/buddy/pipeline-fs/pages/user/favorites/trail-maps.vue?macro=true";
import { default as forgotpasswordIpW8UQHqwfMeta } from "/buddy/pipeline-fs/pages/user/forgotpassword.vue?macro=true";
import { default as verifyaW3MtqQodEMeta } from "/buddy/pipeline-fs/pages/user/group/join/verify.vue?macro=true";
import { default as indexfkZmk1cUtcMeta } from "/buddy/pipeline-fs/pages/user/login/index.vue?macro=true";
import { default as verifykKYKLNNdNdMeta } from "/buddy/pipeline-fs/pages/user/login/verify.vue?macro=true";
import { default as newsletterspW2kPPXkGSMeta } from "/buddy/pipeline-fs/pages/user/notifications/newsletters.vue?macro=true";
import { default as indexbRvrsGhnrMMeta } from "/buddy/pipeline-fs/pages/user/register/index.vue?macro=true";
import { default as verifyZnA4aOJfL8Meta } from "/buddy/pipeline-fs/pages/user/register/verify.vue?macro=true";
import { default as indexwXG6wFO6O1Meta } from "/buddy/pipeline-fs/pages/user/settings/account/index.vue?macro=true";
import { default as changeemail3WjXhBxA7qMeta } from "/buddy/pipeline-fs/pages/user/settings/changeemail.vue?macro=true";
import { default as changepasswordziU5CcztJlMeta } from "/buddy/pipeline-fs/pages/user/settings/changepassword.vue?macro=true";
import { default as indexsJMvjeaNUdMeta } from "/buddy/pipeline-fs/pages/user/settings/delete/index.vue?macro=true";
import { default as verifySf98aLKcj6Meta } from "/buddy/pipeline-fs/pages/user/settings/delete/verify.vue?macro=true";
import { default as verifyDIHMym0S0IMeta } from "/buddy/pipeline-fs/pages/user/settings/email/verify.vue?macro=true";
import { default as camsKzM5I6Ws4JMeta } from "/buddy/pipeline-fs/pages/user/settings/favorites/cams.vue?macro=true";
import { default as daily_45snowshL2Sn2ZiBCMeta } from "/buddy/pipeline-fs/pages/user/settings/favorites/daily-snows.vue?macro=true";
import { default as locationsagMHzVUzBjMeta } from "/buddy/pipeline-fs/pages/user/settings/favorites/locations.vue?macro=true";
import { default as season_45passesGJJxtUF3psMeta } from "/buddy/pipeline-fs/pages/user/settings/favorites/season-passes.vue?macro=true";
import { default as trail_45maps88SyocXj1iMeta } from "/buddy/pipeline-fs/pages/user/settings/favorites/trail-maps.vue?macro=true";
import { default as indexRndulMSs17Meta } from "/buddy/pipeline-fs/pages/user/settings/group/index.vue?macro=true";
import { default as leave7HgzdBrr4JMeta } from "/buddy/pipeline-fs/pages/user/settings/group/leave.vue?macro=true";
import { default as verifyggCCA5SoOMMeta } from "/buddy/pipeline-fs/pages/user/settings/password/verify.vue?macro=true";
import { default as start_45trialAkbBaTDLnEMeta } from "/buddy/pipeline-fs/pages/user/settings/start-trial.vue?macro=true";
import { default as indexSUnYHqko2hMeta } from "/buddy/pipeline-fs/pages/weather-stations/[slug]/index.vue?macro=true";
import { default as _91postId_93Xn95gHJvp4Meta } from "~/pages/dailysnow/post/[postId].vue?macro=true";
import { default as mapQ81q6fQcVWMeta } from "~/pages/explore/[compareType]/[compareId]/map.vue?macro=true";
import { default as mapQsnOQhIR5BMeta } from "~/pages/map.vue?macro=true";
import { default as locationsViXdeJyUOHMeta } from "~/pages/user/settings/favorites/locations?macro=true";
import { default as camssIXCdVvgOZMeta } from "~/pages/user/favorites/cams?macro=true";
import { default as indexh8dBLeJKEOMeta } from "~/pages/user/favorites/locations/index?macro=true";
export default [
  {
    name: aboutxIRS5Bg1mXMeta?.name ?? "about",
    path: aboutxIRS5Bg1mXMeta?.path ?? "/about",
    meta: aboutxIRS5Bg1mXMeta || {},
    alias: aboutxIRS5Bg1mXMeta?.alias || [],
    redirect: aboutxIRS5Bg1mXMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/about.vue").then(m => m.default || m)
  },
  {
    name: allaccessu8zGuJI9EKMeta?.name ?? "allaccess",
    path: allaccessu8zGuJI9EKMeta?.path ?? "/allaccess",
    meta: allaccessu8zGuJI9EKMeta || {},
    alias: allaccessu8zGuJI9EKMeta?.alias || [],
    redirect: allaccessu8zGuJI9EKMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/allaccess.vue").then(m => m.default || m)
  },
  {
    name: apioKfDWekqFhMeta?.name ?? "api",
    path: apioKfDWekqFhMeta?.path ?? "/api",
    meta: apioKfDWekqFhMeta || {},
    alias: apioKfDWekqFhMeta?.alias || [],
    redirect: apioKfDWekqFhMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/api.vue").then(m => m.default || m)
  },
  {
    name: appLZpOciYXZuMeta?.name ?? "app",
    path: appLZpOciYXZuMeta?.path ?? "/app",
    meta: appLZpOciYXZuMeta || {},
    alias: appLZpOciYXZuMeta?.alias || [],
    redirect: appLZpOciYXZuMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/app.vue").then(m => m.default || m)
  },
  {
    name: indexKWHGcg7dipMeta?.name ?? "avalanche-slug",
    path: indexKWHGcg7dipMeta?.path ?? "/avalanche/:slug()",
    meta: indexKWHGcg7dipMeta || {},
    alias: indexKWHGcg7dipMeta?.alias || [],
    redirect: indexKWHGcg7dipMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/avalanche/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: buy3tdfXU0RcTMeta?.name ?? "buy",
    path: buy3tdfXU0RcTMeta?.path ?? "/buy",
    meta: buy3tdfXU0RcTMeta || {},
    alias: buy3tdfXU0RcTMeta?.alias || [],
    redirect: buy3tdfXU0RcTMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/buy.vue").then(m => m.default || m)
  },
  {
    name: careersJAezynu515Meta?.name ?? "careers",
    path: careersJAezynu515Meta?.path ?? "/careers",
    meta: careersJAezynu515Meta || {},
    alias: careersJAezynu515Meta?.alias || [],
    redirect: careersJAezynu515Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: contactZucHUnHjzOMeta?.name ?? "contact",
    path: contactZucHUnHjzOMeta?.path ?? "/contact",
    meta: contactZucHUnHjzOMeta || {},
    alias: contactZucHUnHjzOMeta?.alias || [],
    redirect: contactZucHUnHjzOMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexseUQXZf7IPMeta?.name ?? "dailysnow",
    path: indexseUQXZf7IPMeta?.path ?? "/dailysnow",
    meta: indexseUQXZf7IPMeta || {},
    alias: indexseUQXZf7IPMeta?.alias || [],
    redirect: indexseUQXZf7IPMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/dailysnow/index.vue").then(m => m.default || m)
  },
  {
    name: _91postId_93b9G5rg74e5Meta?.name ?? "dailysnow-post-postId",
    path: _91postId_93b9G5rg74e5Meta?.path ?? "/dailysnow/post/:postId()",
    meta: _91postId_93b9G5rg74e5Meta || {},
    alias: _91postId_93b9G5rg74e5Meta?.alias || [],
    redirect: _91postId_93b9G5rg74e5Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/dailysnow/post/[postId].vue").then(m => m.default || m)
  },
  {
    name: _10_45day_45forecast9Y3XdUwRjRMeta?.name ?? "explore-compareType-compareId-10-day-forecast",
    path: _10_45day_45forecast9Y3XdUwRjRMeta?.path ?? "/explore/:compareType()/:compareId()/10-day-forecast",
    meta: _10_45day_45forecast9Y3XdUwRjRMeta || {},
    alias: _10_45day_45forecast9Y3XdUwRjRMeta?.alias || [],
    redirect: _10_45day_45forecast9Y3XdUwRjRMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/10-day-forecast.vue").then(m => m.default || m)
  },
  {
    name: _10_45day_45historyQMfq6ngzDRMeta?.name ?? "explore-compareType-compareId-10-day-history",
    path: _10_45day_45historyQMfq6ngzDRMeta?.path ?? "/explore/:compareType()/:compareId()/10-day-history",
    meta: _10_45day_45historyQMfq6ngzDRMeta || {},
    alias: _10_45day_45historyQMfq6ngzDRMeta?.alias || [],
    redirect: _10_45day_45historyQMfq6ngzDRMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/10-day-history.vue").then(m => m.default || m)
  },
  {
    name: all_45camsvlbjNnPcOsMeta?.name ?? "explore-compareType-compareId-all-cams",
    path: all_45camsvlbjNnPcOsMeta?.path ?? "/explore/:compareType()/:compareId()/all-cams",
    meta: all_45camsvlbjNnPcOsMeta || {},
    alias: all_45camsvlbjNnPcOsMeta?.alias || [],
    redirect: all_45camsvlbjNnPcOsMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/all-cams.vue").then(m => m.default || m)
  },
  {
    name: indexJZdDAhVyxNMeta?.name ?? "explore-compareType-compareId",
    path: indexJZdDAhVyxNMeta?.path ?? "/explore/:compareType()/:compareId()",
    meta: indexJZdDAhVyxNMeta || {},
    alias: indexJZdDAhVyxNMeta?.alias || [],
    redirect: indexJZdDAhVyxNMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/index.vue").then(m => m.default || m)
  },
  {
    name: mapC3VW67ZcRgMeta?.name ?? "explore-compareType-compareId-map",
    path: mapC3VW67ZcRgMeta?.path ?? "/explore/:compareType()/:compareId()/map",
    meta: mapC3VW67ZcRgMeta || {},
    alias: mapC3VW67ZcRgMeta?.alias || [],
    redirect: mapC3VW67ZcRgMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/map.vue").then(m => m.default || m)
  },
  {
    name: snow_45reportsuP4lBFczZMeta?.name ?? "explore-compareType-compareId-snow-report",
    path: snow_45reportsuP4lBFczZMeta?.path ?? "/explore/:compareType()/:compareId()/snow-report",
    meta: snow_45reportsuP4lBFczZMeta || {},
    alias: snow_45reportsuP4lBFczZMeta?.alias || [],
    redirect: snow_45reportsuP4lBFczZMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-report.vue").then(m => m.default || m)
  },
  {
    name: summaryXs17DIxnkXMeta?.name ?? "explore-compareType-compareId-summary",
    path: summaryXs17DIxnkXMeta?.path ?? "/explore/:compareType()/:compareId()/summary",
    meta: summaryXs17DIxnkXMeta || {},
    alias: summaryXs17DIxnkXMeta?.alias || [],
    redirect: summaryXs17DIxnkXMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/summary.vue").then(m => m.default || m)
  },
  {
    name: temperatureFWeW1BM9SKMeta?.name ?? "explore-compareType-compareId-temperature",
    path: temperatureFWeW1BM9SKMeta?.path ?? "/explore/:compareType()/:compareId()/temperature",
    meta: temperatureFWeW1BM9SKMeta || {},
    alias: temperatureFWeW1BM9SKMeta?.alias || [],
    redirect: temperatureFWeW1BM9SKMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/temperature.vue").then(m => m.default || m)
  },
  {
    name: weather5fTevN7JtsMeta?.name ?? "explore-compareType-compareId-weather",
    path: weather5fTevN7JtsMeta?.path ?? "/explore/:compareType()/:compareId()/weather",
    meta: weather5fTevN7JtsMeta || {},
    alias: weather5fTevN7JtsMeta?.alias || [],
    redirect: weather5fTevN7JtsMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/weather.vue").then(m => m.default || m)
  },
  {
    name: indexerosbRPyKLMeta?.name ?? "explore-powder",
    path: indexerosbRPyKLMeta?.path ?? "/explore/powder",
    meta: indexerosbRPyKLMeta || {},
    alias: indexerosbRPyKLMeta?.alias || [],
    redirect: indexerosbRPyKLMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/powder/index.vue").then(m => m.default || m)
  },
  {
    name: mapJFVZ3ISrMtMeta?.name ?? "explore-powder-map",
    path: mapJFVZ3ISrMtMeta?.path ?? "/explore/powder/map",
    meta: mapJFVZ3ISrMtMeta || {},
    alias: mapJFVZ3ISrMtMeta?.alias || [],
    redirect: mapJFVZ3ISrMtMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/explore/powder/map.vue").then(m => m.default || m)
  },
  {
    name: indexVEr9aZIHdyMeta?.name ?? "index",
    path: indexVEr9aZIHdyMeta?.path ?? "/",
    meta: indexVEr9aZIHdyMeta || {},
    alias: indexVEr9aZIHdyMeta?.alias || [],
    redirect: indexVEr9aZIHdyMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/index.vue").then(m => m.default || m)
  },
  {
    name: avalanche_45forecastkHVzjPrzaUMeta?.name ?? "location-slug-avalanche-forecast",
    path: avalanche_45forecastkHVzjPrzaUMeta?.path ?? "/location/:slug()/avalanche-forecast",
    meta: avalanche_45forecastkHVzjPrzaUMeta || {},
    alias: avalanche_45forecastkHVzjPrzaUMeta?.alias || [],
    redirect: avalanche_45forecastkHVzjPrzaUMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/avalanche-forecast.vue").then(m => m.default || m)
  },
  {
    name: camsXP95R97yEmMeta?.name ?? "location-slug-cams",
    path: camsXP95R97yEmMeta?.path ?? "/location/:slug()/cams",
    meta: camsXP95R97yEmMeta || {},
    alias: camsXP95R97yEmMeta?.alias || [],
    redirect: camsXP95R97yEmMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/cams.vue").then(m => m.default || m)
  },
  {
    name: daily_45snows3370yRhUrOMeta?.name ?? "location-slug-daily-snows",
    path: daily_45snows3370yRhUrOMeta?.path ?? "/location/:slug()/daily-snows",
    meta: daily_45snows3370yRhUrOMeta || {},
    alias: daily_45snows3370yRhUrOMeta?.alias || [],
    redirect: daily_45snows3370yRhUrOMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/daily-snows.vue").then(m => m.default || m)
  },
  {
    name: indexnGdQMrFjWlMeta?.name ?? "location-slug",
    path: indexnGdQMrFjWlMeta?.path ?? "/location/:slug()",
    meta: indexnGdQMrFjWlMeta || {},
    alias: indexnGdQMrFjWlMeta?.alias || [],
    redirect: indexnGdQMrFjWlMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: infoAMKSMr0SLyMeta?.name ?? "location-slug-info",
    path: infoAMKSMr0SLyMeta?.path ?? "/location/:slug()/info",
    meta: infoAMKSMr0SLyMeta || {},
    alias: infoAMKSMr0SLyMeta?.alias || [],
    redirect: infoAMKSMr0SLyMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/info.vue").then(m => m.default || m)
  },
  {
    name: snow_45reportcMWhhXFQWOMeta?.name ?? "location-slug-snow-report",
    path: snow_45reportcMWhhXFQWOMeta?.path ?? "/location/:slug()/snow-report",
    meta: snow_45reportcMWhhXFQWOMeta || {},
    alias: snow_45reportcMWhhXFQWOMeta?.alias || [],
    redirect: snow_45reportcMWhhXFQWOMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/snow-report.vue").then(m => m.default || m)
  },
  {
    name: snow_45summarylqdGwSl5D2Meta?.name ?? "location-slug-snow-summary",
    path: snow_45summarylqdGwSl5D2Meta?.path ?? "/location/:slug()/snow-summary",
    meta: snow_45summarylqdGwSl5D2Meta || {},
    alias: snow_45summarylqdGwSl5D2Meta?.alias || [],
    redirect: snow_45summarylqdGwSl5D2Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/snow-summary.vue").then(m => m.default || m)
  },
  {
    name: trail_45mapsdfzKFNnwB5Meta?.name ?? "location-slug-trail-maps",
    path: trail_45mapsdfzKFNnwB5Meta?.path ?? "/location/:slug()/trail-maps",
    meta: trail_45mapsdfzKFNnwB5Meta || {},
    alias: trail_45mapsdfzKFNnwB5Meta?.alias || [],
    redirect: trail_45mapsdfzKFNnwB5Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/trail-maps.vue").then(m => m.default || m)
  },
  {
    name: weather_45stationsA6OKAlRupsMeta?.name ?? "location-slug-weather-stations",
    path: weather_45stationsA6OKAlRupsMeta?.path ?? "/location/:slug()/weather-stations",
    meta: weather_45stationsA6OKAlRupsMeta || {},
    alias: weather_45stationsA6OKAlRupsMeta?.alias || [],
    redirect: weather_45stationsA6OKAlRupsMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/weather-stations.vue").then(m => m.default || m)
  },
  {
    name: weatheraMWwzrQQI7Meta?.name ?? "location-slug-weather",
    path: weatheraMWwzrQQI7Meta?.path ?? "/location/:slug()/weather",
    meta: weatheraMWwzrQQI7Meta || {},
    alias: weatheraMWwzrQQI7Meta?.alias || [],
    redirect: weatheraMWwzrQQI7Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/weather.vue").then(m => m.default || m)
  },
  {
    name: map18GLMDN5IqMeta?.name ?? "map",
    path: map18GLMDN5IqMeta?.path ?? "/map",
    meta: map18GLMDN5IqMeta || {},
    alias: map18GLMDN5IqMeta?.alias || [],
    redirect: map18GLMDN5IqMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/map.vue").then(m => m.default || m)
  },
  {
    name: _91category_93axYO0EQ3G4Meta?.name ?? "news-category",
    path: _91category_93axYO0EQ3G4Meta?.path ?? "/news/:category()",
    meta: _91category_93axYO0EQ3G4Meta || {},
    alias: _91category_93axYO0EQ3G4Meta?.alias || [],
    redirect: _91category_93axYO0EQ3G4Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/news/[category].vue").then(m => m.default || m)
  },
  {
    name: indexLWWf7Z2GiZMeta?.name ?? "news",
    path: indexLWWf7Z2GiZMeta?.path ?? "/news",
    meta: indexLWWf7Z2GiZMeta || {},
    alias: indexLWWf7Z2GiZMeta?.alias || [],
    redirect: indexLWWf7Z2GiZMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91idOrSlug_934hS0WQx41bMeta?.name ?? "news-post-idOrSlug",
    path: _91idOrSlug_934hS0WQx41bMeta?.path ?? "/news/post/:idOrSlug()",
    meta: _91idOrSlug_934hS0WQx41bMeta || {},
    alias: _91idOrSlug_934hS0WQx41bMeta?.alias || [],
    redirect: _91idOrSlug_934hS0WQx41bMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/news/post/[idOrSlug].vue").then(m => m.default || m)
  },
  {
    name: partnershipsy54PdASnfQMeta?.name ?? "partnerships",
    path: partnershipsy54PdASnfQMeta?.path ?? "/partnerships",
    meta: partnershipsy54PdASnfQMeta || {},
    alias: partnershipsy54PdASnfQMeta?.alias || [],
    redirect: partnershipsy54PdASnfQMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/partnerships.vue").then(m => m.default || m)
  },
  {
    name: privacyLKEtLAwjr8Meta?.name ?? "privacy",
    path: privacyLKEtLAwjr8Meta?.path ?? "/privacy",
    meta: privacyLKEtLAwjr8Meta || {},
    alias: privacyLKEtLAwjr8Meta?.alias || [],
    redirect: privacyLKEtLAwjr8Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: team1kuMI2e3GhMeta?.name ?? "team",
    path: team1kuMI2e3GhMeta?.path ?? "/team",
    meta: team1kuMI2e3GhMeta || {},
    alias: team1kuMI2e3GhMeta?.alias || [],
    redirect: team1kuMI2e3GhMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/team.vue").then(m => m.default || m)
  },
  {
    name: termsIoFGXRJgfAMeta?.name ?? "terms",
    path: termsIoFGXRJgfAMeta?.path ?? "/terms",
    meta: termsIoFGXRJgfAMeta || {},
    alias: termsIoFGXRJgfAMeta?.alias || [],
    redirect: termsIoFGXRJgfAMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: cams9SRLfmRFbZMeta?.name ?? "user-favorites-cams",
    path: cams9SRLfmRFbZMeta?.path ?? "/user/favorites/cams",
    meta: cams9SRLfmRFbZMeta || {},
    alias: cams9SRLfmRFbZMeta?.alias || [],
    redirect: cams9SRLfmRFbZMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/cams.vue").then(m => m.default || m)
  },
  {
    name: daily_45snows5jycoSXQhXMeta?.name ?? "user-favorites-daily-snows",
    path: daily_45snows5jycoSXQhXMeta?.path ?? "/user/favorites/daily-snows",
    meta: daily_45snows5jycoSXQhXMeta || {},
    alias: daily_45snows5jycoSXQhXMeta?.alias || [],
    redirect: daily_45snows5jycoSXQhXMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/daily-snows.vue").then(m => m.default || m)
  },
  {
    name: _10_45day_45forecastgGqhEFofNvMeta?.name ?? "user-favorites-locations-10-day-forecast",
    path: _10_45day_45forecastgGqhEFofNvMeta?.path ?? "/user/favorites/locations/10-day-forecast",
    meta: _10_45day_45forecastgGqhEFofNvMeta || {},
    alias: _10_45day_45forecastgGqhEFofNvMeta?.alias || [],
    redirect: _10_45day_45forecastgGqhEFofNvMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/10-day-forecast.vue").then(m => m.default || m)
  },
  {
    name: _10_45day_45historyEXDiTrD86sMeta?.name ?? "user-favorites-locations-10-day-history",
    path: _10_45day_45historyEXDiTrD86sMeta?.path ?? "/user/favorites/locations/10-day-history",
    meta: _10_45day_45historyEXDiTrD86sMeta || {},
    alias: _10_45day_45historyEXDiTrD86sMeta?.alias || [],
    redirect: _10_45day_45historyEXDiTrD86sMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/10-day-history.vue").then(m => m.default || m)
  },
  {
    name: all_45camszEYDYwpLZkMeta?.name ?? "user-favorites-locations-all-cams",
    path: all_45camszEYDYwpLZkMeta?.path ?? "/user/favorites/locations/all-cams",
    meta: all_45camszEYDYwpLZkMeta || {},
    alias: all_45camszEYDYwpLZkMeta?.alias || [],
    redirect: all_45camszEYDYwpLZkMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/all-cams.vue").then(m => m.default || m)
  },
  {
    name: indexLeke6xxGj1Meta?.name ?? "user-favorites-locations",
    path: indexLeke6xxGj1Meta?.path ?? "/user/favorites/locations",
    meta: indexLeke6xxGj1Meta || {},
    alias: indexLeke6xxGj1Meta?.alias || [],
    redirect: indexLeke6xxGj1Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/index.vue").then(m => m.default || m)
  },
  {
    name: snow_45reportxGS3M75tVBMeta?.name ?? "user-favorites-locations-snow-report",
    path: snow_45reportxGS3M75tVBMeta?.path ?? "/user/favorites/locations/snow-report",
    meta: snow_45reportxGS3M75tVBMeta || {},
    alias: snow_45reportxGS3M75tVBMeta?.alias || [],
    redirect: snow_45reportxGS3M75tVBMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/snow-report.vue").then(m => m.default || m)
  },
  {
    name: summaryv60TAeJycDMeta?.name ?? "user-favorites-locations-summary",
    path: summaryv60TAeJycDMeta?.path ?? "/user/favorites/locations/summary",
    meta: summaryv60TAeJycDMeta || {},
    alias: summaryv60TAeJycDMeta?.alias || [],
    redirect: summaryv60TAeJycDMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/summary.vue").then(m => m.default || m)
  },
  {
    name: temperatureLPDX8kqHzYMeta?.name ?? "user-favorites-locations-temperature",
    path: temperatureLPDX8kqHzYMeta?.path ?? "/user/favorites/locations/temperature",
    meta: temperatureLPDX8kqHzYMeta || {},
    alias: temperatureLPDX8kqHzYMeta?.alias || [],
    redirect: temperatureLPDX8kqHzYMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/temperature.vue").then(m => m.default || m)
  },
  {
    name: weathern6CQ1MlAZ0Meta?.name ?? "user-favorites-locations-weather",
    path: weathern6CQ1MlAZ0Meta?.path ?? "/user/favorites/locations/weather",
    meta: weathern6CQ1MlAZ0Meta || {},
    alias: weathern6CQ1MlAZ0Meta?.alias || [],
    redirect: weathern6CQ1MlAZ0Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/weather.vue").then(m => m.default || m)
  },
  {
    name: mapmRW9zHPcjfMeta?.name ?? "user-favorites-map",
    path: mapmRW9zHPcjfMeta?.path ?? "/user/favorites/map",
    meta: mapmRW9zHPcjfMeta || {},
    alias: mapmRW9zHPcjfMeta?.alias || [],
    redirect: mapmRW9zHPcjfMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/map.vue").then(m => m.default || m)
  },
  {
    name: season_45passesNa12kHQqrsMeta?.name ?? "user-favorites-season-passes",
    path: season_45passesNa12kHQqrsMeta?.path ?? "/user/favorites/season-passes",
    meta: season_45passesNa12kHQqrsMeta || {},
    alias: season_45passesNa12kHQqrsMeta?.alias || [],
    redirect: season_45passesNa12kHQqrsMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/season-passes.vue").then(m => m.default || m)
  },
  {
    name: trail_45mapsOj6i7Sbj6EMeta?.name ?? "user-favorites-trail-maps",
    path: trail_45mapsOj6i7Sbj6EMeta?.path ?? "/user/favorites/trail-maps",
    meta: trail_45mapsOj6i7Sbj6EMeta || {},
    alias: trail_45mapsOj6i7Sbj6EMeta?.alias || [],
    redirect: trail_45mapsOj6i7Sbj6EMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/trail-maps.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordIpW8UQHqwfMeta?.name ?? "user-forgotpassword",
    path: forgotpasswordIpW8UQHqwfMeta?.path ?? "/user/forgotpassword",
    meta: forgotpasswordIpW8UQHqwfMeta || {},
    alias: forgotpasswordIpW8UQHqwfMeta?.alias || [],
    redirect: forgotpasswordIpW8UQHqwfMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: verifyaW3MtqQodEMeta?.name ?? "user-group-join-verify",
    path: verifyaW3MtqQodEMeta?.path ?? "/user/group/join/verify",
    meta: verifyaW3MtqQodEMeta || {},
    alias: verifyaW3MtqQodEMeta?.alias || [],
    redirect: verifyaW3MtqQodEMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/group/join/verify.vue").then(m => m.default || m)
  },
  {
    name: indexfkZmk1cUtcMeta?.name ?? "user-login",
    path: indexfkZmk1cUtcMeta?.path ?? "/user/login",
    meta: indexfkZmk1cUtcMeta || {},
    alias: indexfkZmk1cUtcMeta?.alias || [],
    redirect: indexfkZmk1cUtcMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/login/index.vue").then(m => m.default || m)
  },
  {
    name: verifykKYKLNNdNdMeta?.name ?? "user-login-verify",
    path: verifykKYKLNNdNdMeta?.path ?? "/user/login/verify",
    meta: verifykKYKLNNdNdMeta || {},
    alias: verifykKYKLNNdNdMeta?.alias || [],
    redirect: verifykKYKLNNdNdMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/login/verify.vue").then(m => m.default || m)
  },
  {
    name: newsletterspW2kPPXkGSMeta?.name ?? "user-notifications-newsletters",
    path: newsletterspW2kPPXkGSMeta?.path ?? "/user/notifications/newsletters",
    meta: newsletterspW2kPPXkGSMeta || {},
    alias: newsletterspW2kPPXkGSMeta?.alias || [],
    redirect: newsletterspW2kPPXkGSMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/notifications/newsletters.vue").then(m => m.default || m)
  },
  {
    name: indexbRvrsGhnrMMeta?.name ?? "user-register",
    path: indexbRvrsGhnrMMeta?.path ?? "/user/register",
    meta: indexbRvrsGhnrMMeta || {},
    alias: indexbRvrsGhnrMMeta?.alias || [],
    redirect: indexbRvrsGhnrMMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/register/index.vue").then(m => m.default || m)
  },
  {
    name: verifyZnA4aOJfL8Meta?.name ?? "user-register-verify",
    path: verifyZnA4aOJfL8Meta?.path ?? "/user/register/verify",
    meta: verifyZnA4aOJfL8Meta || {},
    alias: verifyZnA4aOJfL8Meta?.alias || [],
    redirect: verifyZnA4aOJfL8Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/register/verify.vue").then(m => m.default || m)
  },
  {
    name: indexwXG6wFO6O1Meta?.name ?? "user-settings-account",
    path: indexwXG6wFO6O1Meta?.path ?? "/user/settings/account",
    meta: indexwXG6wFO6O1Meta || {},
    alias: indexwXG6wFO6O1Meta?.alias || [],
    redirect: indexwXG6wFO6O1Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/account/index.vue").then(m => m.default || m)
  },
  {
    name: changeemail3WjXhBxA7qMeta?.name ?? "user-settings-changeemail",
    path: changeemail3WjXhBxA7qMeta?.path ?? "/user/settings/changeemail",
    meta: changeemail3WjXhBxA7qMeta || {},
    alias: changeemail3WjXhBxA7qMeta?.alias || [],
    redirect: changeemail3WjXhBxA7qMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/changeemail.vue").then(m => m.default || m)
  },
  {
    name: changepasswordziU5CcztJlMeta?.name ?? "user-settings-changepassword",
    path: changepasswordziU5CcztJlMeta?.path ?? "/user/settings/changepassword",
    meta: changepasswordziU5CcztJlMeta || {},
    alias: changepasswordziU5CcztJlMeta?.alias || [],
    redirect: changepasswordziU5CcztJlMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/changepassword.vue").then(m => m.default || m)
  },
  {
    name: indexsJMvjeaNUdMeta?.name ?? "user-settings-delete",
    path: indexsJMvjeaNUdMeta?.path ?? "/user/settings/delete",
    meta: indexsJMvjeaNUdMeta || {},
    alias: indexsJMvjeaNUdMeta?.alias || [],
    redirect: indexsJMvjeaNUdMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/delete/index.vue").then(m => m.default || m)
  },
  {
    name: verifySf98aLKcj6Meta?.name ?? "user-settings-delete-verify",
    path: verifySf98aLKcj6Meta?.path ?? "/user/settings/delete/verify",
    meta: verifySf98aLKcj6Meta || {},
    alias: verifySf98aLKcj6Meta?.alias || [],
    redirect: verifySf98aLKcj6Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/delete/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyDIHMym0S0IMeta?.name ?? "user-settings-email-verify",
    path: verifyDIHMym0S0IMeta?.path ?? "/user/settings/email/verify",
    meta: verifyDIHMym0S0IMeta || {},
    alias: verifyDIHMym0S0IMeta?.alias || [],
    redirect: verifyDIHMym0S0IMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/email/verify.vue").then(m => m.default || m)
  },
  {
    name: camsKzM5I6Ws4JMeta?.name ?? "user-settings-favorites-cams",
    path: camsKzM5I6Ws4JMeta?.path ?? "/user/settings/favorites/cams",
    meta: camsKzM5I6Ws4JMeta || {},
    alias: camsKzM5I6Ws4JMeta?.alias || [],
    redirect: camsKzM5I6Ws4JMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/favorites/cams.vue").then(m => m.default || m)
  },
  {
    name: daily_45snowshL2Sn2ZiBCMeta?.name ?? "user-settings-favorites-daily-snows",
    path: daily_45snowshL2Sn2ZiBCMeta?.path ?? "/user/settings/favorites/daily-snows",
    meta: daily_45snowshL2Sn2ZiBCMeta || {},
    alias: daily_45snowshL2Sn2ZiBCMeta?.alias || [],
    redirect: daily_45snowshL2Sn2ZiBCMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/favorites/daily-snows.vue").then(m => m.default || m)
  },
  {
    name: locationsagMHzVUzBjMeta?.name ?? "user-settings-favorites-locations",
    path: locationsagMHzVUzBjMeta?.path ?? "/user/settings/favorites/locations",
    meta: locationsagMHzVUzBjMeta || {},
    alias: locationsagMHzVUzBjMeta?.alias || [],
    redirect: locationsagMHzVUzBjMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/favorites/locations.vue").then(m => m.default || m)
  },
  {
    name: season_45passesGJJxtUF3psMeta?.name ?? "user-settings-favorites-season-passes",
    path: season_45passesGJJxtUF3psMeta?.path ?? "/user/settings/favorites/season-passes",
    meta: season_45passesGJJxtUF3psMeta || {},
    alias: season_45passesGJJxtUF3psMeta?.alias || [],
    redirect: season_45passesGJJxtUF3psMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/favorites/season-passes.vue").then(m => m.default || m)
  },
  {
    name: trail_45maps88SyocXj1iMeta?.name ?? "user-settings-favorites-trail-maps",
    path: trail_45maps88SyocXj1iMeta?.path ?? "/user/settings/favorites/trail-maps",
    meta: trail_45maps88SyocXj1iMeta || {},
    alias: trail_45maps88SyocXj1iMeta?.alias || [],
    redirect: trail_45maps88SyocXj1iMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/favorites/trail-maps.vue").then(m => m.default || m)
  },
  {
    name: indexRndulMSs17Meta?.name ?? "user-settings-group",
    path: indexRndulMSs17Meta?.path ?? "/user/settings/group",
    meta: indexRndulMSs17Meta || {},
    alias: indexRndulMSs17Meta?.alias || [],
    redirect: indexRndulMSs17Meta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/group/index.vue").then(m => m.default || m)
  },
  {
    name: leave7HgzdBrr4JMeta?.name ?? "user-settings-group-leave",
    path: leave7HgzdBrr4JMeta?.path ?? "/user/settings/group/leave",
    meta: leave7HgzdBrr4JMeta || {},
    alias: leave7HgzdBrr4JMeta?.alias || [],
    redirect: leave7HgzdBrr4JMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/group/leave.vue").then(m => m.default || m)
  },
  {
    name: verifyggCCA5SoOMMeta?.name ?? "user-settings-password-verify",
    path: verifyggCCA5SoOMMeta?.path ?? "/user/settings/password/verify",
    meta: verifyggCCA5SoOMMeta || {},
    alias: verifyggCCA5SoOMMeta?.alias || [],
    redirect: verifyggCCA5SoOMMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/password/verify.vue").then(m => m.default || m)
  },
  {
    name: start_45trialAkbBaTDLnEMeta?.name ?? "user-settings-start-trial",
    path: start_45trialAkbBaTDLnEMeta?.path ?? "/user/settings/start-trial",
    meta: start_45trialAkbBaTDLnEMeta || {},
    alias: start_45trialAkbBaTDLnEMeta?.alias || [],
    redirect: start_45trialAkbBaTDLnEMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/user/settings/start-trial.vue").then(m => m.default || m)
  },
  {
    name: indexSUnYHqko2hMeta?.name ?? "weather-stations-slug",
    path: indexSUnYHqko2hMeta?.path ?? "/weather-stations/:slug()",
    meta: indexSUnYHqko2hMeta || {},
    alias: indexSUnYHqko2hMeta?.alias || [],
    redirect: indexSUnYHqko2hMeta?.redirect,
    component: () => import("/buddy/pipeline-fs/pages/weather-stations/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91postId_93Xn95gHJvp4Meta?.name ?? "dailysnow-slug-post-id",
    path: _91postId_93Xn95gHJvp4Meta?.path ?? "/dailysnow/:slug/post/:postId",
    meta: _91postId_93Xn95gHJvp4Meta || {},
    alias: _91postId_93Xn95gHJvp4Meta?.alias || [],
    redirect: _91postId_93Xn95gHJvp4Meta?.redirect,
    component: () => import("~/pages/dailysnow/post/[postId].vue").then(m => m.default || m)
  },
  {
    name: _91postId_93Xn95gHJvp4Meta?.name ?? "dailysnow-slug",
    path: _91postId_93Xn95gHJvp4Meta?.path ?? "/dailysnow/:slug",
    meta: _91postId_93Xn95gHJvp4Meta || {},
    alias: _91postId_93Xn95gHJvp4Meta?.alias || [],
    redirect: _91postId_93Xn95gHJvp4Meta?.redirect,
    component: () => import("~/pages/dailysnow/post/[postId].vue").then(m => m.default || m)
  },
  {
    name: mapQ81q6fQcVWMeta?.name ?? "explore-type-id-map-latlngzoom",
    path: mapQ81q6fQcVWMeta?.path ?? "/explore/:compareType/:compareId/map/:latlngzoom",
    meta: mapQ81q6fQcVWMeta || {},
    alias: mapQ81q6fQcVWMeta?.alias || [],
    redirect: mapQ81q6fQcVWMeta?.redirect,
    component: () => import("~/pages/explore/[compareType]/[compareId]/map.vue").then(m => m.default || m)
  },
  {
    name: mapQsnOQhIR5BMeta?.name ?? "map-basemap",
    path: mapQsnOQhIR5BMeta?.path ?? "/map/:basemap",
    meta: mapQsnOQhIR5BMeta || {},
    alias: mapQsnOQhIR5BMeta?.alias || [],
    redirect: mapQsnOQhIR5BMeta?.redirect,
    component: () => import("~/pages/map.vue").then(m => m.default || m)
  },
  {
    name: mapQsnOQhIR5BMeta?.name ?? "map-basemap-overlay",
    path: mapQsnOQhIR5BMeta?.path ?? "/map/:basemap/:overlay",
    meta: mapQsnOQhIR5BMeta || {},
    alias: mapQsnOQhIR5BMeta?.alias || [],
    redirect: mapQsnOQhIR5BMeta?.redirect,
    component: () => import("~/pages/map.vue").then(m => m.default || m)
  },
  {
    name: mapQsnOQhIR5BMeta?.name ?? "map-basemap-overlay-latlngzoom",
    path: mapQsnOQhIR5BMeta?.path ?? "/map/:basemap/:overlay/:latlngzoom",
    meta: mapQsnOQhIR5BMeta || {},
    alias: mapQsnOQhIR5BMeta?.alias || [],
    redirect: mapQsnOQhIR5BMeta?.redirect,
    component: () => import("~/pages/map.vue").then(m => m.default || m)
  },
  {
    name: locationsViXdeJyUOHMeta?.name ?? "user-settings-favorites",
    path: locationsViXdeJyUOHMeta?.path ?? "/user/settings/favorites",
    meta: locationsViXdeJyUOHMeta || {},
    alias: locationsViXdeJyUOHMeta?.alias || [],
    redirect: locationsViXdeJyUOHMeta?.redirect,
    component: () => import("~/pages/user/settings/favorites/locations").then(m => m.default || m)
  },
  {
    name: camssIXCdVvgOZMeta?.name ?? "user-favorites-cam-id",
    path: camssIXCdVvgOZMeta?.path ?? "/user/favorites/cam/:id",
    meta: camssIXCdVvgOZMeta || {},
    alias: camssIXCdVvgOZMeta?.alias || [],
    redirect: camssIXCdVvgOZMeta?.redirect,
    component: () => import("~/pages/user/favorites/cams").then(m => m.default || m)
  },
  {
    name: indexh8dBLeJKEOMeta?.name ?? "user-favorites",
    path: indexh8dBLeJKEOMeta?.path ?? "/user/favorites",
    meta: indexh8dBLeJKEOMeta || {},
    alias: indexh8dBLeJKEOMeta?.alias || [],
    redirect: indexh8dBLeJKEOMeta?.redirect,
    component: () => import("~/pages/user/favorites/locations/index").then(m => m.default || m)
  }
]