import revive_payload_client_4sVQNw7RlN from "/buddy/pipeline-fs/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/buddy/pipeline-fs/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/buddy/pipeline-fs/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/buddy/pipeline-fs/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/buddy/pipeline-fs/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/buddy/pipeline-fs/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/buddy/pipeline-fs/.nuxt/floating-vue.mjs";
import plugin_8SbxDRbG6Y from "/buddy/pipeline-fs/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/buddy/pipeline-fs/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/buddy/pipeline-fs/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_QQzOodE9HA from "/buddy/pipeline-fs/plugins/api.js";
import braze_client_qym2UMLgSq from "/buddy/pipeline-fs/plugins/braze.client.js";
import chart_js_client_IPqJCjjEDe from "/buddy/pipeline-fs/plugins/chart-js.client.js";
import dayjs_FoMn5Zigri from "/buddy/pipeline-fs/plugins/dayjs.js";
import digilant_client_sNCoIadD6V from "/buddy/pipeline-fs/plugins/digilant.client.js";
import fancyapps_ui_client_1wnQe9NyWs from "/buddy/pipeline-fs/plugins/fancyapps-ui.client.js";
import loading_JsMeH12IfP from "/buddy/pipeline-fs/plugins/loading.js";
import map_service_o2q5Pkus9u from "/buddy/pipeline-fs/plugins/map-service.js";
import sentry_client_GoGQuZo4Et from "/buddy/pipeline-fs/plugins/sentry.client.js";
import v_calendar_client_Eahmfkhy07 from "/buddy/pipeline-fs/plugins/v-calendar.client.js";
import vee_validate_client_x6euUz9Rze from "/buddy/pipeline-fs/plugins/vee-validate.client.js";
import vue_draggable_client_OlAgW8lMLf from "/buddy/pipeline-fs/plugins/vue-draggable.client.js";
import vue_fontawesome_SRjmhbwGFO from "/buddy/pipeline-fs/plugins/vue-fontawesome.js";
import vue_gtag_client_fbnXi0Aqdd from "/buddy/pipeline-fs/plugins/vue-gtag.client.js";
import vue_toast_notification_BrJMpoMpes from "/buddy/pipeline-fs/plugins/vue-toast-notification.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_8SbxDRbG6Y,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  api_QQzOodE9HA,
  braze_client_qym2UMLgSq,
  chart_js_client_IPqJCjjEDe,
  dayjs_FoMn5Zigri,
  digilant_client_sNCoIadD6V,
  fancyapps_ui_client_1wnQe9NyWs,
  loading_JsMeH12IfP,
  map_service_o2q5Pkus9u,
  sentry_client_GoGQuZo4Et,
  v_calendar_client_Eahmfkhy07,
  vee_validate_client_x6euUz9Rze,
  vue_draggable_client_OlAgW8lMLf,
  vue_fontawesome_SRjmhbwGFO,
  vue_gtag_client_fbnXi0Aqdd,
  vue_toast_notification_BrJMpoMpes
]