import * as braze from '@braze/web-sdk';
import { useRuntimeConfig } from 'nuxt/app';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public;
  const { query } = useRoute();

  if (config.isVitest) {
    console.log('Braze: Not loaded, in Vitest!');
    return;
  }

  if (!config.brazeSdkApiKey || !config.brazeSdkEndpoint) {
    console.log('Braze: Not loaded, missing API key or SDK endpoint');
    return;
  }

  if (query.in_app_view === 'true') {
    console.log('Braze: Not loaded, in app view');
    return;
  }

  // Initialize Braze using the configuration values from the .env file. Note that by default
  // logging is enabled in development. In staging and production, it can be enabled from the
  // developer console by running: $nuxt.$braze.toggleLogging()
  const success = braze.initialize(config.brazeSdkApiKey, {
    allowUserSuppliedJavascript: true,
    baseUrl: config.brazeSdkEndpoint,
    enableLogging: config.isDev,
  });

  if (success) {
    return {
      provide: {
        braze,
      },
    };
  }
  else {
    console.log('Braze: Unable to initialize Braze');
  }
});
