export default defineNuxtPlugin(() => {
  const { isDev, isVitest } = useRuntimeConfig().public;

  if (isVitest) {
    console.log('Digilant: Not loaded, in Vitest!');
    return;
  }

  if (isDev) {
    console.log('Digilant: Not loaded, in development');
    return;
  }

  const { query } = useRoute();

  if (query.in_app_view === 'true') {
    console.log('Digilant: Not loaded, in app view');
    return;
  }

  window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
  window._adftrack.push({
    HttpHost: 'a2.adform.net',
    pm: 3104993,
    divider: encodeURIComponent('|'),
    pagename: encodeURIComponent('Retargeting'),
  });

  /* eslint @stylistic/max-statements-per-line: off */
  (function () { const s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; const x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }());
});
