<template>
  <SiteNavBar
    :class="['lg:tw-sticky lg:tw-top-0 tw-z-30', $style.container]"
  >
    <template #brand>
      <NuxtLink to="/">
        <img
          :alt="config.siteName"
          class="tw-w-48 tw-inline"
          src="~/assets/img/OpenSnowLogo.png"
        >
      </NuxtLink>
      <AllAccessBadge :class-names="allAccessBadgeClassNames" />
    </template>
    <template #menu>
      <div
        v-if="canShowNavContent"
        :class="searchClass"
      >
        <SiteHeaderSearch />
      </div>
      <ul
        v-if="canShowNavContent"
        class="lg:tw-flex"
      >
        <li
          v-for="pageLink in pageLinks"
          :key="pageLink.text"
          :class="[navItemClass, 'lg:tw-mr-4']"
        >
          <Component
            :is="getLinkComponent(pageLink)"
            :class="[navItemAnchorClass, getNavItemAnchorActiveClass(pageLink)]"
            :href="getLinkHref(pageLink)"
            :to="pageLink.to"
            @click="handleCollapseNav"
          >
            <font-awesome-icon
              v-if="pageLink.icon"
              :class="navItemIconClass"
              :icon="pageLink.icon"
            />
            <Component
              :is="pageLink.component"
              v-else-if="pageLink.component"
              :class="navItemIconClass"
            />
            {{ pageLink.text }}
          </Component>
        </li>
      </ul>
      <ul :class="userLinksClass">
        <li
          v-for="userLink in userLinks"
          :key="userLink.text"
          :class="[
            navItemClass,
            'lg:tw-ml-4',
          ]"
        >
          <Button
            v-if="userLink.buttonClass"
            :additional-class-names="userLink.buttonClass"
            :display-block="true"
            :to="userLink.to"
            :type="userLink.type"
          >
            {{ userLink.text }}
          </Button>
          <Component
            :is="getLinkComponent(userLink)"
            v-else
            :class="[navItemAnchorClass, getNavItemAnchorActiveClass(userLink)]"
            :href="getLinkHref(userLink)"
            :target="userLink.target"
            :to="userLink.to"
            @click="handleCollapseNav"
          >
            <font-awesome-icon
              v-if="userLink.icon"
              :class="navItemIconClass"
              :icon="userLink.icon"
            />
            {{ userLink.text }}
          </Component>
        </li>
      </ul>
    </template>
  </SiteNavBar>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { isScreenLg } from '@@/utils/CommonUtils';
import { addReturnTo, getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { useUserStore } from '@@/stores/User';
import { useUiStore } from '@@/stores/Ui';
import SiteHeaderSearch from './SiteHeaderSearch.vue';
import DailySnowIcon from '~/assets/svg/dailysnow-icon.svg?skipsvgo';
import MapIcon from '~/assets/svg/map.svg?skipsvgo';

/**
 * The site header has slightly different displays for different user types:
 *
 * - Guest User: Help Link no Icon, Log In Link no Icon, Start Free Trial Button
 * - Registered Free User: Help Link with Icon, Settings Link with Icon, Start Free Trial Button
 * - Registered All-Access User: Help Link with Icon, Settings Link with Icon
 */
export default {
  name: 'SiteHeader',

  components: {
    SiteHeaderSearch,
  },

  setup() {
    const config = useRuntimeConfig().public;
    return { config };
  },

  computed: {
    ...mapState(useUserStore, ['canShowAuthContent', 'isAllAccess', 'isGuest']),

    allAccessBadgeClassNames() {
      return [
        this.isAllAccess
          ? `tw-hidden lg:tw-inline-block ${this.$style.showAllAccess}`
          : 'tw-hidden',
        'tw-align-middle tw-whitespace-nowrap tw-overflow-x-hidden',
        this.$style.allAccessBadge,
      ];
    },

    canShowNavContent() {
      return !this.isGuest;
    },

    helpLink() {
      return {
        href: 'https://support.opensnow.com',
        icon: this.isGuest ? null : 'question',
        target: '_blank',
        text: 'Help',
      };
    },

    logInLink() {
      const query = this.getReturnToQuery();
      const path = '/user/login';
      const text = 'Log In';
      const to = addReturnTo(path, query);

      return { text, to };
    },

    navItemAnchorClass() {
      return 'tw-block lg:tw-inline-block tw-py-2 lg:tw-px-2 tw-cursor-pointer hover:tw-no-underline';
    },

    navItemClass() {
      return [
        this.isGuest ? 'lg:tw-block lg:tw-py-2' : '',
        'tw-text-base tw-font-normal',
        this.isGuest ? 'lg:tw-text-sm' : 'lg:tw-text-xs',
        'text-light-color tw-whitespace-nowrap',
      ];
    },

    navItemIconClass() {
      return 'tw-inline-block lg:tw-block tw-w-6 tw-h-4 tw-mr-1.5 lg:tw-mb-1 lg:tw-mx-auto tw-fill-current';
    },

    pageLinks() {
      return [
        {
          icon: 'star',
          text: 'Favorites',
          to: '/user/favorites',
        },
        {
          component: MapIcon,
          text: 'Maps',
          to: '/map',
        },
        {
          href: '/explore',
          icon: 'compass',
          text: 'Explore',
        },
        {
          component: DailySnowIcon,
          text: 'Daily Snows',
          to: '/dailysnow',
        },
        {
          icon: 'newspaper',
          text: 'News',
          to: '/news',
        },
      ];
    },

    searchClass() {
      return 'tw-relative tw-flex-auto tw-my-4 tw-mx-0 lg:tw-my-0 lg:tw-mr-4 lg:tw-order-2';
    },

    settingsLink() {
      return {
        href: '/user/settings/account',
        icon: 'cog',
        text: 'Settings',
      };
    },

    startFreeTrialButton() {
      const query = this.getReturnToQuery();

      const params = {
        isGuest: this.isGuest,
        query: {
          ...query,
          source: 'snow_header',
        },
      };

      const buttonClass = [
        'tw-text-base lg:tw-text-sm',
        this.isGuest ? '' : 'lg:tw-my-2',
        'tw-w-full',
        'tw-whitespace-nowrap',
      ].join(' ');
      const text = 'Start Free Trial';
      const to = getStartFreeTrialUrl(params);
      const type = ButtonTypes.allAccess;

      return {
        buttonClass,
        text,
        to,
        type,
      };
    },

    userLinks() {
      if (this.isGuest) {
        return [
          this.helpLink,
          this.logInLink,
          this.startFreeTrialButton,
        ];
      }

      if (!this.isAllAccess) {
        return [
          this.helpLink,
          this.settingsLink,
          this.startFreeTrialButton,
        ];
      }

      return [
        this.helpLink,
        this.settingsLink,
      ];
    },

    userLinksClass() {
      return `lg:tw-flex lg:tw-order-3 tw-mt-1 lg:tw-mt-0 tw-pt-1 lg:tw-pt-0 tw-border-t lg:tw-border-t-0 ${this.$style.userLinks}`;
    },
  },

  watch: {
    /**
     * Always try to collapse the Site Nav Bar when the user navigates to a different page so it
     * isn't awkwardly left open when a new page is displayed.
     */
    $route(to, from) {
      if (to?.path !== from?.path) {
        this.handleCollapseNav();
      }
    },
  },

  methods: {
    ...mapActions(useUiStore, ['setShouldCollapseNav']),

    getLinkComponent({ href }) {
      return href ? 'a' : resolveComponent('NuxtLink');
    },

    getLinkHref(link) {
      return link.href ? link.href : link.to;
    },

    getNavItemAnchorActiveClass(pageLink) {
      const path = String(pageLink.href);
      return this.$route.path.startsWith(path) ? 'tw-text-white' : null;
    },

    getReturnToQuery() {
      /* eslint camelcase: off */
      const return_to = this.$route?.query?.return_to || this.$route?.fullPath;

      if (typeof return_to !== 'string') {
        return {};
      }

      if (return_to.includes('/user/login')
        || return_to.includes('/user/register')
        || return_to === '/') {
        return {};
      }

      return { return_to };
    },

    handleCollapseNav() {
      if (isScreenLg()) {
        // Don't collapse the navbar on large screens because it's not open!
        return;
      }

      window.setTimeout(() => {
        this.setShouldCollapseNav(true);
      }, 250);
    },
  },
};
</script>

<style module>
.allAccessBadge {
  margin-left: -1.3rem;
  max-width: 0;
  opacity: 0;
  transition:
    all
    var(--auth-content-transition-duration)
    var(--auth-content-transition-function)
    var(--auth-content-transition-duration);
}

/*
 * TODO: Currently hiding the all access badge on mobile like the current site. This could probably
 * be implemented better in the future so that the hamburger doesn't wrap.
 */
@media (width >= 992px) {
  .allAccessBadge.showAllAccess {
    margin-left: 0.35rem;
    max-width: 4.5rem;
    opacity: 1;
  }
}

.userLinks {
  border-color: var(--user-links-border-color);
}

@media (width >= 992px) {
  .userLinks {
    transition:
      width,
      opacity
      var(--auth-content-transition-duration)
      var(--auth-content-transition-function);
  }

  .userLinks.hideAuthContent {
    opacity: 0;
    width: 13.125rem;
  }
}

.container :global(.nuxt-link-active) {
  color: white;
}
</style>
