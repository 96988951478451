import mapboxgl from 'mapbox-gl';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public;
  let el;
  let map;

  const mapService = {
    /**
     * Return a new, or the the existing, mapboxgl.Map instance to a component so that it can
     * render a map.
     */
    getMap(options = {}) {
      mapboxgl.accessToken = config.mapboxAccessToken;

      if (!el) {
        el = document.getElementById('mapContainer');
      }

      if (!el) {
        throw new Error('Unable to find map container!');
      }

      el.classList.remove('tw-hidden');

      if (options?.container) {
        options.container.appendChild(el);
      }

      let willLoad = false;

      const {
        bearing = 0,
        center,
        dragRotate = false,
        logoPosition = 'top-left',
        maxPitch = 0,
        pitch = 0,
        projection = 'mercator',
        style,
        zoom,
      } = options;

      if (!map) {
        map = new mapboxgl.Map({
          attributionControl: false,
          bearing,
          center,
          container: el,
          dragRotate,
          fadeDuration: 150,
          keyboard: false,
          logoPosition,
          maxPitch,
          pitch,
          projection,
          style,
          touchPitch: false,
          zoom,
        });

        willLoad = true;
      }
      else {
        if (typeof bearing === 'number') {
          map.setBearing(bearing);
        }

        if (center) {
          map.setCenter(center);
        }

        if (typeof dragRotate === 'boolean') {
          if (dragRotate) {
            map.dragRotate.enable();
          }
          else {
            map.dragRotate.disable();
          }
        }

        if (typeof maxPitch === 'number') {
          map.setMaxPitch(maxPitch);
        }

        if (typeof pitch === 'number') {
          map.setPitch(pitch);
        }

        if (projection) {
          map.setProjection(projection);
        }

        if (style) {
          map.setStyle(style);
        }

        if (zoom) {
          map.setZoom(zoom);
        }

        map.resize();
      }

      return { map, willLoad };
    },

    /**
     * The component that rendered the map should call the returnMap() method in the beforeDestroy
     * lifecycle hook when it is done with the map. The returnMap() method will hide the map element
     * and move it back to the <main id="root"> element so it remains in the DOM.
     * @todo Perform any other additional clean up of the map instance here! Or perhaps this will
     * be done by the caller?
     */
    returnMap() {
      el.classList.add('tw-hidden');

      const main = document.getElementById('root');
      main.appendChild(el);
    },
  };

  return {
    provide: { mapService },
  };
});
