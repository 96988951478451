const previous = {
  path: null,
  position: null,
};

export default {
  /**
   * If this is a pop state navigation (browser back/forward) then use the previous position.
   * Otherwise, scroll to the top of the next page smoothly. Note that we do _not_ use
   * the savedPosition passed to us from Vue Router because when navigating forward via a pop state
   * event the savedPosition is incorrect.
   *
   * For example, starting on the /user/favorites page we scroll down 2000px, and then click on a
   * link to the /points/{slug} page. On the /points/{slug} page we do not scroll, and just click the
   * back button. When navigating back to /user/favorites the savedPosition will be { x: 0, y: 2000 }
   * as expected. However, when we then click the forward button to navigate forward to the
   * /points/{slug} page we are passed a savePosition of { x: 0, y: 2000 } which is incorrect! We
   * should have been passed { x: 0, y: 0 } since no scrolling occurred on /points/{slug}.
   *
   * To work around this bug we keep track of the previous path and position manually so that when
   * navigating back to the previous page, with either the forward or back button, we navigate to
   * the user's last scroll position.
   *
   * @see https://nuxtjs.org/docs/configuration-glossary/configuration-router/#scrollbehavior
   * @see https://router.vuejs.org/guide/advanced/scroll-behavior.html
   */
  scrollBehavior(to, from) {
    // console.log(`scrollBehavior(): to.path = ${to.path}, from.path = ${from.path}`);

    let delay = 0;
    let scrollTo = { left: 0, top: 0, behavior: 'smooth' };

    const isPopStateNavigation = () => (previous.path
      && previous.position
      && to
      && to.path === previous.path);

    if (isPopStateNavigation()) {
      // Note that de-structuring assignment is used here so that when a new previous.position is
      // saved below we don't accidentally modify the scrollTo position!
      scrollTo = { ...previous.position };

      // A slight delay is used when navigating back/forward, to a previous position, so that the
      // page has enough content to scroll to the requested position.
      delay = 1000;
    }

    // Save previous from path and scroll position
    previous.path = from.path;
    previous.position = { left: window.scrollX, top: window.scrollY };

    return new Promise((resolve) => {
      window.setTimeout(() => {
        // console.log(`scrollBehavior(): scrollTo = ${JSON.stringify(scrollTo)}, delay = ${delay}`);
        resolve(scrollTo);
      }, delay);
    });
  },
};
